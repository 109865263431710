<template>
  <!-- 新增轮播图 -->
  <div class="positionT0L0">
    <p class="fw600">基本信息</p>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 15 }"
    >
      <a-form-model-item label="名称" prop="name" labelAlign="left">
        <a-input v-model="form.name" :maxLength="32" />
      </a-form-model-item>
      <a-form-model-item
        label="首页轮播图"
        prop="imageUrl"
        labelAlign="left"
        required
      >
        <!-- <CropperUpload
          avatarTextTop="推荐使用1920*600px,JPG.PNG.JPEG格式"
          avatarTextBottom="图片小于2M"
          :imageUrl="form.imageUrl"
          :options="imageUrlOptions"
          @avatarfn="imageUrlFn"
        /> -->
        <MyUpload
          avatarTextTop="推荐使用1920*600px,JPG.PNG.JPEG格式"
          avatarTextBottom="图片小于2M"
          :imageUrl="form.imageUrl"
          @avatarfn="imageUrlFn"
        />
      </a-form-model-item>
      <FooterToolBar :collapsed="sideCollapsed">
        <a-button class="margin_right60" @click="() => $router.go(-1)">
          返回
        </a-button>
        <a-button type="primary" :disabled="keepDis" @click="keepClick(form)">
          保存
        </a-button>
      </FooterToolBar>
    </a-form-model>
  </div>
</template>

<script>
import CropperUpload from "@/components/file/CropperUpload";
import MyUpload from "@/components/file/MyUpload";
import FooterToolBar from "@/components/FooterToolbar";
import { message } from "ant-design-vue";
import { baseMixin } from "@/store/app-mixin";
import {
  BannerAddApi,
  BannerDetailApi,
  BannerUpdApi,
} from "@/request/api/operate.js";
export default {
  mixins: [baseMixin],
  components: { CropperUpload, FooterToolBar, MyUpload },
  created() {
    let id = this.$route.params.id;
    if (id != 0) {
      this.form.id = id;
      BannerDetailApi({ id: id }).then(({ code, data }) => {
        if (code == 200) {
          this.form = data;
        }
      });
    }
  },
  data() {
    return {
      form: {
        id: "",
        name: "",
        imageUrl: "",
      },
      rules: {
        name: [
          { required: true, message: "输入的内容不能为空", trigger: "blur" },
        ],
      },
      imageUrlOptions: {
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 1920, //默认生成截图框宽度
        autoCropHeight: 600, //默认生成截图框高度
        fixedBox: true, //是否固定截图框大小 不允许改变
        previewsCircle: false, //预览图是否是原圆形
        outputType: "png",
        title: "修改图片",
      },
      keepDis: false,
    };
  },
  methods: {
    keepClick() {
      this.keepDis = true;
      if (!this.form.imageUrl) {
        message.error("请上传轮播图");
        this.keepDis = false;
        return;
      }
      this.$refs["ruleForm"].validate((result) => {
        if (result) {
          if (!this.form.id) {
            BannerAddApi(this.form).then(({ code, data }) => {
              if (code == 200) {
                message.success("新增成功");
                this.keepDis = false;
                this.$router.push(`/operate/bannerList`);
              } else {
                setTimeout(() => {
                  this.keepDis = false;
                }, 3000);
              }
            });
          } else {
            BannerUpdApi(this.form).then(({ code, data }) => {
              if (code == 200) {
                message.success("修改成功");
                this.keepDis = false;
                this.$router.push(`/operate/bannerList`);
              } else {
                setTimeout(() => {
                  this.keepDis = false;
                }, 3000);
              }
            });
          }
        } else {
          message.error("请正确填写表单");
          setTimeout(() => {
            this.keepDis = false;
          }, 3000);
        }
      });
    },
    imageUrlFn(obj) {
      this.form.imageUrl = obj.url;
    },
  },
};
</script>

<style lang="less" scoped></style>
